import React, { useState } from 'react'
import StyledBox from '../../components/layout/Panel'
import Typography from '@mui/material/Typography'
import { H1 } from '../../components/common/heading'
import PaymentMethodTable from './PaymentMethodTable'
import { PaymentOption } from '../../types/PaymentOption'
import { StyledStandardButton } from '../../components/common/button/Buttons'
import { useParams } from 'react-router-dom'
import theme from '../../assets/theme'
import { PaymentData } from '../../types/PaymentData'
import { SelectedPaymentOption } from '../../types/SelectedPaymentOption'
import { useUpdatePaymentJourneyInfo } from '../../custom-hooks/useUpdatePaymentJourney'
import { OrderDetails } from '../../types/OrderDetails'
import { OrderStep } from '../../types/enums/OrderStep'
import Loading from '../../components/common/loading/Loading'
import { PaymentMethod } from '../../types/enums/PaymentMethod'
import { useCompleteOrder } from '../../custom-hooks/useCompleteOrder'
import BuyerSpendingLimit from './BuyerSpendingLimit'
import { BuyerRiskDecision } from '../../types/BuyerRiskDecision'
import { StyledButtonsContainer } from '../../components/layout/Container'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ReturnToMartketplaceDialog from '../../components/common/returnToMarketplace'
import styled from '@emotion/styled'
import { SoleTraderAgreementDocument } from '../../assets/storageAccountUploadLinks'
import Link from '@mui/material/Link'
import { BusinessType } from '../../types/enums/BusinessType'
import { MarketplaceRepaymentsBankAccount } from '../../types/MarketplaceRepaymentsBankAccount'
import MarketplaceRepaymentsBankAccountDetails from './MarketplaceRepaymentsBankAccountDetails'
import { MfaStatuses } from '../../types/enums/PaymentJourneyStatus'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

export type PaymentDataProps = {
  paymentDetails: PaymentData
  riskDecision: BuyerRiskDecision
  marketPlaceName: string
  paymentOptions: Array<PaymentOption>
  selectedPaymentOption: SelectedPaymentOption
  orderDetails: OrderDetails
  lastCompletedStep: string | undefined
  paymentCancelledRedirectUrl: string | undefined
  marketplaceRepaymentsBankAccount: MarketplaceRepaymentsBankAccount | undefined
}

const StyledPanel = styled(StyledBox)`
  padding: 0 0 ${theme.spacing(11)} 0;
`

const Payment = (props: PaymentDataProps) => {
  useVirtualPageView(VirtualPage.PAYMENT)
  //TO DO to change for the first available payment options when they will be available
  const firstPaymentOption = props.paymentOptions.map((option) => option.name)[0] || ''
  const [paymentOption, setPaymentOption] = useState<string>(props.selectedPaymentOption?.option || firstPaymentOption)
  const { shortCode } = useParams()
  const [isCompleting, setIsCompleting] = useState<boolean>(props.lastCompletedStep === OrderStep.Payment)
  const { company } = props.orderDetails

  const completeOrderMutation = useCompleteOrder({
    onSuccess: (data) => {
      window.location.replace(data.marketplaceRedirectUrl)
    }
  })

  const updateLastCompletedStepAndStatusMutation = useUpdatePaymentJourneyInfo({
    onSuccess: (data) => {
      if (data.paymentJourney.status != null && MfaStatuses.includes(data.paymentJourney.status)) {
        return
      }

      setIsCompleting(true)
      completeOrderMutation.mutate()
    }
  })

  const handleContinueClick = () => {
    updateLastCompletedStepAndStatusMutation.mutate({
      orderDetails: {
        ...props.orderDetails,
        selectedPayment: { method: PaymentMethod.MarketPay, option: paymentOption }
      },
      journey: {
        lastCompletedStep: OrderStep.Payment
      }
    })
  }

  const refreshPage = () => {
    window.location.reload()
  }

  if (isCompleting) {
    return (
      <Loading
        header={`We're taking you back to ${props.marketPlaceName} to complete your order. This may take a few seconds`}
        bodyMessage=""
        body={
          <>
            <a href={`/${shortCode as string}/payment`} onClick={refreshPage} data-cy="refresh-page-button">
              Refresh your page
            </a>{' '}
            if you haven't been redirected to {props.marketPlaceName} in 10 seconds.
          </>
        }
      ></Loading>
    )
  }

  return (
    <>
      <StyledPanel>
        <BuyerSpendingLimit
          totalSpendingLimit={props.riskDecision.totalSpendingLimit}
          availableLimit={props.riskDecision.availableLimit}
          status={props.riskDecision.status}
          currency={props.paymentDetails.currency}
        />
      </StyledPanel>
      <div>
        <H1 dataCy="payment-method-title">Select a payment method</H1>
        <Typography variant="body1" data-cy="payment-method-description">
          {props.paymentOptions.length === 1
            ? 'Great news! You can choose the payment method below to place your order.'
            : 'Great news! You can choose any of the payment methods below to place your order.'}
        </Typography>

        <PaymentMethodTable {...props} paymentOption={paymentOption} setPaymentOption={setPaymentOption} />

        {props.marketplaceRepaymentsBankAccount && (
          <MarketplaceRepaymentsBankAccountDetails
            marketplaceRepaymentsBankAccount={props.marketplaceRepaymentsBankAccount}
          />
        )}

        <H1 dataCy="whats-next">What's next?</H1>
        <Typography variant="body1" data-cy="whats-next-description">
          You're almost there. We'll redirect you back to {props.marketPlaceName} where all you have left to do is
          confirm your order. We'll take care of the rest.
          {company.type === BusinessType.SoleTrader && company.companyIdentifier !== null ? (
            <div data-cy="soletrader-agreement" style={{ marginBottom: 0 }}>
              <p>
                By clicking Continue, you confirm that you are authorised to pay using Kriya Payments and you accept the{' '}
                <Link
                  variant="body2"
                  href={SoleTraderAgreementDocument}
                  data-cy="soletrader-agreement-link"
                  aria-label="Download the Sole trader Agreement Document"
                >
                  Kriya Customer Agreement.
                </Link>
              </p>
            </div>
          ) : null}
        </Typography>
      </div>
      <StyledButtonsContainer>
        <StyledStandardButton
          variant="contained"
          endIcon={<ChevronRightIcon />}
          onClick={handleContinueClick}
          id="continue-payment-button"
          disabled={updateLastCompletedStepAndStatusMutation.isLoading || completeOrderMutation.isLoading}
        >
          Continue
        </StyledStandardButton>
        <ReturnToMartketplaceDialog
          marketplace={props.marketPlaceName}
          redirectUrl={props.paymentCancelledRedirectUrl}
          disabled={updateLastCompletedStepAndStatusMutation.isLoading || completeOrderMutation.isLoading}
        ></ReturnToMartketplaceDialog>
      </StyledButtonsContainer>
    </>
  )
}

export default Payment
