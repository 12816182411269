import React from 'react'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import Typography from '@mui/material/Typography'
import { BusinessType } from '../../../types/enums/BusinessType'
import FormControlLabel from '@mui/material/FormControlLabel'
import PannelWithDefaultButtons from '../../../components/common/button/PannelWithDefaultButtons'
import theme from '../../../assets/theme'
import { useBusinessTypeContext } from '../BusinessTypeContext'

export type CompanyStructureOldProps = {
  marketplaceName: string
  redirectUrl: string
  isSoleTraderTypeSupported: boolean
}

const CompanyStructureOld = ({ marketplaceName, redirectUrl, isSoleTraderTypeSupported }: CompanyStructureOldProps) => {
  const { companyType, setCompanyType } = useBusinessTypeContext()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyType(event.target.value)
  }

  return (
    <>
      <Typography data-cy="company-structure-header" variant="caption">
        What is your business structure?
      </Typography>
      <Box>
        <RadioGroup
          aria-labelledby="company-structure-radio-buttons-group"
          name="company-structure-radio-buttons-group"
          value={companyType}
          onChange={onChange}
        >
          <FormControlLabel
            value={BusinessType.LimitedCompany}
            control={<Radio id="limited-company-radio" />}
            label="Limited company"
          />
          {isSoleTraderTypeSupported && (
            <FormControlLabel
              value={BusinessType.SoleTrader}
              control={<Radio id="sole-trader-radio" />}
              label="Sole trader"
            />
          )}
          <FormControlLabel
            value={BusinessType.GovernmentEntity}
            control={<Radio id="government-entity-radio" />}
            label="Government entity"
          />
        </RadioGroup>
      </Box>
      {!companyType ? (
        <PannelWithDefaultButtons
          marketplaceName={marketplaceName}
          redirectUrl={redirectUrl}
          loading={false}
          disabled={true}
        />
      ) : (
        <Box style={{ marginTop: theme.spacing(11) }}></Box>
      )}
    </>
  )
}

export default CompanyStructureOld
