import React from 'react'
import Typography from '@mui/material/Typography'
import { Company } from '../../../types/Company'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

const captionStyling = {
  Typography: 'inputLabel'
}

export type CompanyAndDeliveryProps = {
  companyDetails: Company
  countryName: string
}

const CompanyDetails: React.FC<CompanyAndDeliveryProps> = ({ companyDetails, countryName }) => {
  return (
    <>
      <FormControl>
        <FormLabel sx={captionStyling}>Your company</FormLabel>
        <Typography data-cy="companyDetails.companyName" variant="caption2">
          {companyDetails.companyName}
        </Typography>
      </FormControl>
      {companyDetails.companyRegistrationNumber && (
        <FormControl>
          <FormLabel sx={captionStyling}>Your company’s registration number</FormLabel>
          <Typography data-cy="companyDetails.companyRegistrationNumber" variant="caption2">
            {companyDetails.companyRegistrationNumber}
          </Typography>
        </FormControl>
      )}
      <FormControl>
        <FormLabel sx={captionStyling}>Your company’s country on incorporation</FormLabel>
        <Typography data-cy="companyDetails.countryOfRegistration" variant="caption2">
          {countryName}
        </Typography>
      </FormControl>
    </>
  )
}

export default CompanyDetails
