import unleashApiKey from './unleashApiKeyMapping'

export const unleashConfig = {
  url: 'https://flags.kriya.co/api/frontend',
  clientKey: unleashApiKey(window.location.href),
  refreshInterval: 15,
  appName: 'Payments'
}

export const AutoCompleteOrderOn2FaApproval = 'ENT-621_2fa_auto_complete_order'
export const IdentifyLimitedCompaniesByEmailOrPhone = 'ENT-703_identify_ltd_with_phone_or_email'
