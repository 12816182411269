import { apiClient } from '../api/apiClient'
import { useMutation, useQueryClient } from 'react-query'
import { fetchOrderKey } from './useOrderInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { UserIdentifiers } from '../types/UserIdentifiers'

export const prePopulatePaymentJourneyInfo = async (
  userIdentifiers: UserIdentifiers,
  shortCode: string
): Promise<number> => {
  const response = await apiClient.post(`paymentjourney/${shortCode}/prepopulate`, userIdentifiers)
  return response.status
}

export const usePrePopulatePaymentJourneyInfo = ({
  onSuccess,
  onError
}: {
  onSuccess?: () => void
  onError?: () => void
}) => {
  const { shortCode } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const prePopulatePaymentJourneyMutation = useMutation(
    async (identifiers: UserIdentifiers): Promise<number> => {
      const data = await prePopulatePaymentJourneyInfo(identifiers, shortCode as string)
      return data
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOrderKey)

        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate('/error')
        }
      }
    }
  )

  return prePopulatePaymentJourneyMutation
}
