import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import TextInput from '../../../components/input/TextInput'
import { emailPattern } from '../../../components/input/emailRegExp'
import useFetchCacheData from '../../../custom-hooks/useFetchCacheData'
import { defaultCountryCode, defaultCountryName, defaultCurrency } from '../../../consts'
import PannelWithDefaultButtons from '../../../components/common/button/PannelWithDefaultButtons'
import IdentifierSelection from './IdentifierSelection'
import { UserIdentifier } from '../../../types/enums/UserIdentifier'
import PhoneInput from '../../../components/input/PhoneInput/PhoneInput'
import { Country } from '../../../types/Country'
import theme from '../../../assets/theme'
import Box from '@mui/material/Box'
import { usePrePopulatePaymentJourneyInfo } from '../../../custom-hooks/usePrePopulatePaymentJourney'
import { UserIdentifiers } from '../../../types/UserIdentifiers'
import useTrackEvent from '../../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../../types/enums/TrackEventType'
import { GetUserIdentifiers, SetUserIdentifiers } from '../../../utils/userInfoPopulation'

export type UserIdentificationProps = {
  availableCountries?: Country[]
}

const UserIdentification = ({ availableCountries }: UserIdentificationProps) => {
  const paymentJourneyData = useFetchCacheData()
  availableCountries = availableCountries ?? [
    { countryCode: defaultCountryCode, countryName: defaultCountryName, defaultCurrency: defaultCurrency }
  ]
  const prePopulateMutation = usePrePopulatePaymentJourneyInfo({})
  const savedInformation = GetUserIdentifiers()
  const preselectedType =
    savedInformation?.phoneNumber !== undefined ? UserIdentifier.PhoneNumber : UserIdentifier.Email
  const trackEvent = useTrackEvent()
  const form = useForm<UserIdentifiers>({
    mode: 'onChange',
    defaultValues: {
      email: savedInformation?.email,
      phoneNumber: savedInformation?.phoneNumber
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = form

  const onSubmit = (formValues: UserIdentifiers) => {
    if (userIdentifier === UserIdentifier.Email) formValues.phoneNumber = undefined
    else formValues.email = undefined

    trackEvent(EventTypes.CUSTOMER_DETAILS.USER_IDENTIFICATION, {
      selectedOption: userIdentifier,
      inputValue: (userIdentifier === UserIdentifier.Email ? formValues.email : formValues.phoneNumber) ?? ''
    })

    SetUserIdentifiers(formValues)
    prePopulateMutation.mutate(formValues)
  }

  const [userIdentifier, setUserIdentifier] = useState<UserIdentifier>(preselectedType)

  return (
    <form id="pre-population-form" onSubmit={handleSubmit(onSubmit)}>
      <IdentifierSelection identifier={userIdentifier} onIdentifierChanged={setUserIdentifier} />
      <Box sx={{ typography: 'body2', marginTop: theme.spacing(10) }}>
        {userIdentifier === UserIdentifier.Email ? (
          <TextInput
            label="Email address"
            name="email"
            register={register}
            validation={{
              required: 'Please enter your email address',
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid email address'
              },
              maxLength: {
                value: 128,
                message: 'Maximum 128 characters'
              }
            }}
            error={errors.email}
            style={{ pb: '0' }}
          />
        ) : (
          <PhoneInput
            label="Mobile number"
            name="phoneNumber"
            control={control}
            error={errors.phoneNumber}
            validation={{ isValidated: true, errorMessage: 'Please enter a valid mobile number' }}
            defaultCountry={defaultCountryCode}
            availableCountries={availableCountries.map((c) => c.countryCode)}
            disableDropdown={false}
          />
        )}
      </Box>

      <PannelWithDefaultButtons
        marketplaceName={paymentJourneyData?.marketplace.name as string}
        redirectUrl={paymentJourneyData?.paymentJourney.paymentCancelledRedirectUrl as string}
        loading={prePopulateMutation.isLoading}
        disabled={!form.formState.isValid}
      />
    </form>
  )
}

export default UserIdentification
