import React from 'react'
import { usePaymentJourneyStatusPolling } from '../../custom-hooks/usePaymentJourneyStatusPoling'
import Loading from '../../components/common/loading/Loading'
import MfaPollingContent from './MfaPollingContent'
import { useNavigate, useParams } from 'react-router-dom'
import { PaymentJourneyStatus } from '../../types/enums/PaymentJourneyStatus'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const MfaConfirmationPolling = () => {
  useVirtualPageView(VirtualPage.MFA_CONFIRMATION_POLLING)
  const navigate = useNavigate()
  const { shortCode } = useParams()

  usePaymentJourneyStatusPolling({
    onError: () => {
      navigate('/error')
    },
    onComplete: (data) => {
      if (
        (data.status === PaymentJourneyStatus.MfaSessionApproved || data.status === PaymentJourneyStatus.Completed) &&
        shortCode != null
      ) {
        navigate(`/${shortCode}/mfa-approved`)
      }
    }
  })

  return (
    <Loading
      header={'Awaiting SMS Confirmation'}
      isTrustPilotWidgetDisplayed={false}
      body={<MfaPollingContent />}
    ></Loading>
  )
}

export default MfaConfirmationPolling
