import React, { Dispatch, SetStateAction } from 'react'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import { UserIdentifier } from '../../../types/enums/UserIdentifier'
import theme from '../../../assets/theme'

export type IdentifierSelectionProps = {
  identifier: UserIdentifier
  onIdentifierChanged: Dispatch<SetStateAction<UserIdentifier>>
}

const IdentifierSelection = ({ identifier, onIdentifierChanged }: IdentifierSelectionProps) => {
  const onChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onIdentifierChanged(value as UserIdentifier)
  }

  return (
    <>
      <Typography data-cy="requester-identifier-header" variant="caption">
        How do you want to identify yourself?
      </Typography>
      <Typography data-cy="requester-identifier-description" variant="body2" sx={{ marginTop: theme.spacing(4) }}>
        If you have used Kriya PayLater before, please use the same email or number you registered before
      </Typography>
      <Box>
        <RadioGroup
          aria-labelledby="requester-identification-radio-buttons-group"
          name="requester-identification-radio-buttons-group"
          onChange={onChange}
          value={identifier}
        >
          <FormControlLabel value={UserIdentifier.Email} control={<Radio id="email-radio" />} label="Email" />
          <FormControlLabel
            value={UserIdentifier.PhoneNumber}
            control={<Radio id="phoneNumber-radio" />}
            label="Mobile number"
          />
        </RadioGroup>
      </Box>
    </>
  )
}

export default IdentifierSelection
