import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import Typography from '@mui/material/Typography'
import { BusinessType } from '../../../types/enums/BusinessType'
import FormControlLabel from '@mui/material/FormControlLabel'
import PannelWithDefaultButtons from '../../../components/common/button/PannelWithDefaultButtons'
import { useBusinessTypeContext } from '../BusinessTypeContext'

export type CompanyStructureProps = {
  marketplaceName: string
  redirectUrl: string
}

const CompanyStructure = ({ marketplaceName, redirectUrl }: CompanyStructureProps) => {
  const { companyType, setCompanyType } = useBusinessTypeContext()
  const [selectedCompanyType, setSelectedCompanyType] = useState<string | undefined>(companyType)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCompanyType(event.target.value)
  }

  const onClick = () => {
    setCompanyType(selectedCompanyType)
  }

  return (
    <>
      <Typography data-cy="company-structure-header" variant="caption">
        What is your business structure?
      </Typography>
      <Box>
        <RadioGroup
          aria-labelledby="company-structure-radio-buttons-group"
          name="company-structure-radio-buttons-group"
          value={companyType}
          onChange={onChange}
        >
          <FormControlLabel
            value={BusinessType.LimitedCompany}
            control={<Radio id="limited-company-radio" />}
            label="Limited company"
          />
          <FormControlLabel
            value={BusinessType.GovernmentEntity}
            control={<Radio id="government-entity-radio" />}
            label="Government entity"
          />
        </RadioGroup>
      </Box>

      <PannelWithDefaultButtons
        marketplaceName={marketplaceName}
        redirectUrl={redirectUrl}
        loading={false}
        disabled={!selectedCompanyType}
        onClick={onClick}
      />
    </>
  )
}

export default CompanyStructure
